import {getFormattedWebsitePath} from "@/utilities";

export default ({breadcrumbs = []} = {}) => {

    const {locale, t} = useI18n()

    const items = breadcrumbs.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        name: item.title,
        item: getFormattedWebsitePath(item.link)
    }))

    return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: t('niceone'),
                item: getFormattedWebsitePath('/')
            },
            ...items
        ]
    }
}