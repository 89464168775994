<template>
  <nav class="flex justify-between">
    <div class="inline-flex items-center">
      <div>
        <nuxt-link :to="$routerUrl('/')"
                   class="block p-2 text-primary text-xs">
          {{ $t('menu.home') }}
        </nuxt-link>
      </div>
      <span v-if="items.length" class="mx-1 text-txt-secondary text-xs">/</span>
      <div v-for="(val, index) in items" class="flex items-center">
        <div :key="index">
          <template v-if="val.link && index !== items.length - 1">
            <nuxt-link :to="$routerUrl(val.link)" class="block p-2 text-primary text-xs">{{ val.title }}</nuxt-link>
          </template>
          <template v-else>
            <span class="block p-2 text-txt-secondary text-xs">{{ val.title }}</span>
          </template>
        </div>
        <span v-if="index < items.length - 1" class="mx-1 text-boulder text-xs">/</span>
      </div>
    </div>
  </nav>
</template>

<script setup>
import BreadcrumbsJsonld from "@/jsonld/breadcrumbs";

const props = defineProps({
  items: {
    type: Array,
    default: []
  }
})

useJsonld(BreadcrumbsJsonld({breadcrumbs: props.items}))
</script>

<style lang="scss" scoped>

</style>